@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Noto Sans', sans-serif;
    background-color: white;
}